<template>
  <VApp id="tt-app">
    <VMain>
      <RouterView />
    </VMain>
  </VApp>
</template>

<script>
export default {
  name: 'DefaultLayout',
};
</script>
